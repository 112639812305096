.topic-carousel {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 4;
}

.topic-carousel-mobile {
    position: relative;
    min-height: var(--max-topic-carousel-height-px);
    width: 100vw;
    top: 0;
    z-index: 4;
}

.topic-titles-container {
    overflow-x: auto;
    scroll-behavior: smooth;
    display: flex;
    min-height: calc(var(--max-toolbar-height-px));
    color: var(--dark-mode-text-color);
}

.topic-title::-webkit-scrollbar {
    display: none;
}

.topic-label {
    display: block;
    font-size: 0.8em;
    font-weight: 600;
    /* color: gray; */
    color: var(--dark-mode-secondary-text-color);
    font-style: italic;
    text-align: center;
}

.topic-title {
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    font-size: 2em;
    /* color: var(--neutral-color); */
    color: var(--dark-mode-text-color);
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    overflow-y: hidden;
}

.topic-title-mobile {
    position: absolute;
    width: 68vw;
    min-height: calc(var(--max-toolbar-height-px));
    text-align: center;
    font-size: 1.6em;
    color: var(--dark-mode-text-color);
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
    transition: all 0.3s ease;
    padding: 0 1vw;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.topic-title-mobile::-webkit-scrollbar {
    display: none;
}

.prev-topic-titles, .next-topic-titles {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 13vw;
    height: 4em;
    font-size: 9px;
    text-align: center;
    transition: all 0.3s ease;
    color: var(--dark-mode-secondary-text-color);
    overflow-x: hidden;
}

@keyframes jump {
    to {
        transform: translate(0, 0.3em);
    }
}

.topic-votes-container {
    display: flex;
    width: 100%;
    color: var(--gray);
}

@media only screen and (max-width: 350px) {
    .topic-votes-container {
        display: block;
    }
}

.topic-vote-text-left, .topic-vote-text-right {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; 
    z-index: 2;
    padding-top: 3px;
}

.topic-vote-text-left {
    left: 26vw;
    border-radius: 50px 10px 10px 50px;
    color: #602d86;
}

.topic-vote-text-right {
    right: 26vw;
    border-radius: 10px 50px 50px 10px;
    color: #1c4d44;
}

.prev-next-topic {
    color: gray;
}

@media only screen and (max-width: 600px) {
    .topic-vote-text {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
    .topic-title {
        font-size: 1.5em;
        min-height: 40px;
    }
}