.account-container {
    color: var(--dark-mode-text-color);
    overflow: auto;
}

.account-title {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
}

.cancel-account-action {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 20px;
    left: 10px;
    border-radius: 50%;
    padding: 5px;
}

.cancel-account-action:hover {
    cursor: pointer;
    background-color: #ffffff25;
}

.change-password-container {
    margin-top: 20px;
}

.change-password-input-container {
    display: flex;
}

.change-password-input-error {
    margin: auto;
}

.change-password-error-message {
    text-align: center;
    color: red;
}

.delete-account-message {
    margin: 30px 20px 30px 20px;
    text-align: center;
}