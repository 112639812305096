.about-container {
    text-align: center;
    padding: 5px;
    color: var(--dark-mode-text-color);
    font-size: large;
}

.about-container h4 {
    padding: 5px;
}

.about-container a {
    color: var(--dark-mode-text-color);
    font-weight: bold;
}