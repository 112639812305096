.cards-shell {
    position: absolute;
    height: calc(100% - var(--max-topic-carousel-height-px));
    width: 100%;
    top: var(--max-topic-carousel-height-px);
    margin-left: 27.5vw;
}

.card-shell {
    display: flex;
    position: absolute;
    height: 95%;
    width: 45vw;
    border-radius: 25px;
    margin-left: unset;
    margin-right: unset;
    /* background: white; */
    /* box-shadow: 0 5px 50px 0 rgba(32,33,36,0.28); */
    background: var(--dark-mode-foreground);
    overflow: hidden;
}

.card-shell-cover {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 25px;
    /* background-color: grey; */
    background-color: var(--dark-mode-middleground);
    opacity: 0;
    cursor: pointer;
    z-index: 2;
}

.card-shell-cover:hover{
    /* background-color: var(--highlight-color); */
    background-color: var(--dark-mode-highlight-color);
}

.card-shell-cover-label {
    position:absolute;
    height: 100%;
    width: 0;
    right: 1vw;
    font-size: 2em;
    z-index: 3;
    pointer-events: none;
    writing-mode: vertical-rl;
    text-align: center;

    /* darkmode styles*/
    color: var(--dark-mode-text-color);
}

@media only screen and (max-width: 600px) {
    .cards-shell {
        margin-left: 0%;
    }

    .card-shell {
        z-index: 2;
        height: 90%;
        width: 100%;
        top: 0%;
        border-radius: 25px;
        background-color: var(--dark-mode-middleground);
    }

    .card-shell-cover:hover{
        background-color: var(--dark-mode-middleground);
    }

    .card-shell-cover-label {
        width: 100%;
        right: unset;
        top: 25%;
        writing-mode: horizontal-tb;
        text-align: center;

        /* darkmode styles*/
        color: var(--dark-mode-text-color);
    }
}