.app-shell {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.app-shell::-webkit-scrollbar {
  display: none;
}

.topic-content {
  position: relative;
  height: 100%;
  width: 100%;
  scroll-snap-align: start;
  z-index: 1;
}

.cards-shell-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0;
}

.fixed-content {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 4;
}

@media only screen and (max-width: 600px) {
  .app-shell {
    scroll-snap-type: unset;
  }

  .topic-content {
    scroll-snap-align: unset;
  }
}