.analytics {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
  border-radius: 25px;
}

.canvas-container {
  width: 100%;
  margin: auto;
  touch-action: none;
}

@media only screen and (max-height: 600px) {
  .canvas-container {
    width: 90%;
  }
}

.chart {
  max-width: 100% !important;
  margin: auto;
}

.analytics-footer, .analytics-footer-fullscreen {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 0.5px solid dimgray;
}

.analytics-footer {
  border-radius: 0 0 25px 25px;
}

.analytics-footer-fullscreen {
  z-index: 3;
}

.numeric-display {
  width: 95%;
  margin: auto;
  margin-top: 1em;
}

.numeric-display-title {
  color: var(--dark-mode-text-color);
  font-size: 18px;
  padding-bottom: 2px;
}

.numeric-display-body {
  display: flex;
}

.numeric-display-separator {
  color: white;
}

.numeric-display-halal, .numeric-display-haram {
  text-align: center;
  display: grid;
}

.numeric-display-halal {
  color: var(--halal-color);
  margin-right: auto;
}

.numeric-display-haram {
  color: var(--haram-color);
  margin-left: auto;
}

.numeric-display-label {
  margin-left: auto;
  width: 5em;
  font-size: 12px;
}

.numeric-display-value {
  font-size: 18px;
}

.interval-selector-container {
  display: flex;
}

.interval-selector, .interval-selector-selected {
  margin: auto;
  width: 50px;
  padding: 5px 0 5px 0;
  border-radius: 10px;
  color: var(--dark-mode-secondary-text-color);
}

.interval-selector:hover {
  color: white;
  cursor: pointer;
}

.interval-selector-selected {
  color: white;
  background-color: var(--dark-mode-background);
}

@media only screen and (max-width: 600px) {
  .numeric-display-title {
    font-size: 18px !important;
  }

  .numeric-display-label {
    font-size: 12px !important;
  }

  .numeric-display-value {
    font-size: 18px !important;
  }

  .interval-selector-container {
    font-size: 12px !important;
  }

}