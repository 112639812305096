.images-body {
    scroll-behavior: auto;
    scroll-snap-type: y mandatory;
}

.image-container {
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.profile-container {
    color: var(--dark-mode-text-color);
    overflow: auto;
    border-radius: 25px;
}

.profile-container::-webkit-scrollbar {
    display: none;
}

.profile-header-section {
    z-index: 10;
    border-radius: 25px 25px 0 0;
    position: absolute;
    width: 100%;
    height: 7em;
    background-color: var(--dark-mode-foreground);
    box-shadow: 0 5px 50px 0 rgba(32,33,36,0.28);
}

.profile-title {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    padding-top: 1em;
}

.profile-tabs {
    padding-top: 1em;
    display: flex;
    height: 3.5em;
    list-style-type: none;
}

.profile-tab, .profile-tab-selected {
    font-size: 14px;
    line-height: 4em;
    margin: auto;
    height: 100%;
    width: 33%;
    text-align: center;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-tab:hover, .profile-tab-selected:hover {
    cursor: pointer;
    color: white;
}

.profile-tab-selected {
    color: white;
    box-shadow: inset 0 0 10px var(--gray);
}

.profile-body {
    margin-top: 7em;
    height: calc(100% - 7em);
    overflow: hidden;
}

.comments-section-container {
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.comments-section-container::-webkit-scrollbar {
    display: none;
}

.topics-section-container {
    list-style: none;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.topics-section-container::-webkit-scrollbar {
    display: none;
}

.user-topic-li {
    display: flex;
    padding: 0 1em 0 1em;
}

.user-topic-container {
    width: 100%;
    padding: 1em 0 1em 0;
    transition: all .2s ease-in-out;
}

.user-topic-container:hover {
    color: white;
    cursor: pointer;
}

.topic-meta-info-container {
    font-size: 11px;
}

.topic-meta-info-item {
    margin-right: 1em;
}

.topic-header {
    font-size: .75em;
    color: var(--dark-mode-secondary-text-color);
}

.profile-topic-title {
    font-size: 1.1em;
    line-height: 2em;
    color: white;
}

.user-comment-container {
    display: flex;
    padding: 1em 1em 0 1em;
    position: relative;
    transition: all .2s ease-in-out;
}

.user-comment-container:hover {
    color: white;
    cursor: pointer;
    transition: scale;
}

.user-comment-bullet-separator {
    margin-top: 0.4em;
    font-size: xx-small;
    margin-left: 5px;
    margin-right: 5px;
}

.user-comment {
    color: inherit;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.user-username {
    width: fit-content;
    font-family: proxima-semibold, PingFangSC, sans-serif;
    font-weight: 600;
    font-size: 18px;
    /* color: black; */
    color: var(--dark-mode-secondary-text-color);
}

.user-comment p {
    margin: 0.2em 0 0.2em 0;
}

.user-comment-time-stamp {
    padding-top: 0.15em;
}

.user-likes-container {
    position: relative;
    display: table-cell;
    margin: 1em 0 0 0;
    right: 0;
    width: fit-content;
    font-size: 12px;
}

.media-topic-title {
    padding: 5px;
    background: rgba(0,0,0,0.4);
    border-radius: 10px;
    position: absolute;
    bottom: 0.75em;
    left: 50%;
    transform: translate(-50%, 0);
}

.media-topic-title:hover {
    cursor: pointer;
    color: white;
}

@media only screen and (max-width: 600px) {
    .user-username {
        font-size: 11px !important;
    }
    
    .user-comment {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
        font-size: 12px !important;
        line-height: 16px !important;
    }

    .user-comment p {
        margin: 0em 0 0em 0 !important;
    }
    
    .user-likes-container {
        font-size: 10.5px;
    }

}