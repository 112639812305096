.voting-container {
    display: flex;
    margin: auto;
}

.voting-switch-container {
    position: relative;
    /* background: gray; */
    background: var(--dark-mode-middleground);
    margin: auto;
}

.voting-switch {
    position: absolute;
    /* background: white; */
    background: dimgrey;
    cursor: pointer;
}

.voting-switch-text {
    font-size: 0.8em;
    margin-top: 13px;
    font-weight: 600;
    text-align: center;
    /* color: var(--neutral-color); */
    color: var(--dark-mode-text-color);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.voting-area {
    position: absolute;
    cursor: pointer;
}

.voting-area-filled-haram {
    position: absolute;
    cursor: pointer;
    background: linear-gradient(to left, var(--dark-mode-middleground), var(--dark-mode-haram-color), var(--dark-mode-haram-color));
}

.voting-area-filled-halal {
    position: absolute;
    cursor: pointer;
    background: linear-gradient(to right, var(--dark-mode-middleground), var(--dark-mode-halal-color), var(--dark-mode-halal-color));
}

.voting-label {
    position: relative;
    text-align: center;
    width: 100px;
    font-size: 1.1em;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
}

.number-haram, .number-halal {
    width: 3.5em;
    height: 3.5em;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50%;
    text-align: center;
    line-height: 3.5em;
    font-size: 10px;
    font-weight: 600;
}

.number-haram {
    margin-left: auto;
    background: var(--dark-mode-haram-color);
    color: white;
}

.number-halal {
    margin-right: auto;
    background: var(--dark-mode-halal-color);
    color: white;
}

@media only screen and (max-width: 600px) {
    .voting-label {
        position: relative;
        text-align: center;
        width: 60px;
        font-size: 0.8em;
        line-height: 1.5em;
        font-weight: 600;
        margin-top: auto;
        margin-bottom: auto;
    }
    .voting-switch-text {
        font-size: 0.7em;
        line-height: 1.2em;
    }

    .number-haram, .number-halal {
        width: 3.5em;
        height: 3.5em;
        line-height: 3.5em;
        font-size: 10px;
        font-weight: 600;
    }
}