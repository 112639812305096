.modal {
    height: 0;
    width: 0;
    position: relative;
    display: flow-root;
    margin: auto;
    border-radius: 25px 25px 25px 25px;
    background-color: var(--dark-mode-middleground);
    z-index: 4;
    overflow: hidden;
    left: -50%;
    top: 50%;
    transform: translateY(-50%);
}

.modal-cover {
    height: calc(100vh);
    bottom: 0;
    width: 100vw;
    position: fixed;
    background-color: #808080;
    opacity: 0.8;
    z-index: 4;
    left: 0;
}