.images-body {
  display: block;
  height: 100%;
  width: 100%;
  overflow: auto;
  text-align: center;
  scroll-behavior: auto;
  scroll-snap-type: y mandatory;
  background: black;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.images-body::-webkit-scrollbar {
  display: none;
}

.show-add-image-button, .hide-add-image-button {
  position: absolute;
  height: 34px;
  width: 60px;
  bottom: 8px;
  left: calc(50% - 30px);
  border-radius: 30px;
  background-color: var(--neutral-color);
  color: var(--dark-mode-text-color);
  cursor: pointer;
  box-shadow: 0px 0px 5px 5px rgba(32,33,36,0.5);
}

.show-add-image-button, .show-add-image-button-fullscreen {
  transform: scale(0);
  animation: appear 0.3s ease-in-out forwards;
}

.hide-add-image-button, .hide-add-image-button-fullscreen {
  transform: scale(1);
  animation: disappear 0.3s ease-in-out forwards;
}

.preview-displayed {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0px;
  background: black;
  z-index: 1;
  /* left: calc(50% - 30px); */
}

.more-images-below, .more-images-above {
  position: absolute;
  height: 22px;
  width: 22px;
  fill: gray;
  background: rgba(0,0,0,0.4);
  border-radius: 10px;
  padding: 4px;
}
.more-images-below:hover, .more-images-above:hover {
  cursor: pointer;
}
.more-images-below {
  bottom: 3px;
  line-height: 28px;
  animation: jumpdown 0.5s infinite ease-out alternate;
}
.more-images-above {
  top: 0px;
  line-height: 20px;
  animation: jumpup 0.5s infinite ease-out alternate;
}

@keyframes jumpdown {
  from {
    transform: translate(0, 0.3em)
  }
  to {
      transform: translate(0, 0);
  }
}

@keyframes jumpup {
  from {
    transform: translate(0, 0)
  }
  to {
      transform: translate(0, 0.3em);
  }
}

.image-container {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  overflow: hidden;
}

.image-navigator-button {
  position: absolute;
  height: 40px;
  width: 40px;
  bottom: -64px;
  padding: 12px;
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 30px;
  background-color: var(--neutral-color);
  color: var(--site-background-color);
  outline: none;
}

.image-navigator-button-left {
  left: calc(50% - 84px);
}

.image-navigator-button-right {
  right: calc(50% - 84px);
}

.image-navigator-button:hover {
  background-color: var(--light-neutral-color);
}

.image {
  max-height: 100%;
  max-width: 100%;
}

.image-username {
  background: rgba(0,0,0,0.4);
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 0.75em 0.75em;
  height: fit-content;
  width: fit-content;
  color: white;
  cursor: pointer;
  text-align: center;
  z-index: 1;
}

.image-username:hover {
  color: var(--light-neutral-color);
}

.image-delete-button {
  padding: 5px;
  background: rgba(0,0,0,0.4);
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  margin-bottom: 6em;
  right: 0.75em;
  cursor: pointer;
  text-align: center;
  color: white;
  z-index: 1;
}

.no-media-text {
  top: calc(50% - 0.5em);
  position: absolute;
  height: auto;
  width: 100%;
  margin: auto;
  color: var(--dark-mode-secondary-text-color);
}

.image-delete-button:hover {
  color: red;
}

.image-likes-container {
  padding: 5px;
  background: rgba(0,0,0,0.4);
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  margin-bottom: 1em;
  right: 0.75em;
  cursor: pointer;
  display: table;
  text-align: center;
  z-index: 1;
}

.image-likes {
  height: 20px;
}

.image-heart {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.image-heart:hover {
  color: var(--light-neutral-color);
}

@keyframes appear {
  to {
      transform: scale(1);
  }
}

@keyframes disappear {
  to {
      transform: scale(0);
  }
}

.show-add-image-button:hover {
  background-color: var(--light-neutral-color);
}

.add-image-body {
  border-radius: 25px 25px 25px 25px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  text-align: center;
}

.add-image-back-button {
  position: absolute;
  height: 34px;
  width: 60px;
  bottom: 8px;
  left: calc(50% - 30px);
  border-radius: 30px;
  background-color: var(--neutral-color);
  color: var(--dark-mode-text-color);
  cursor: pointer;
  box-shadow: 0px 0px 5px 5px rgba(32,33,36,0.5);
  border: none;
}

.add-image-section-text {
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: var(--dark-mode-secondary-text-color);
}

.image-preview-title {
  position: absolute;
  background: rgba(0,0,0,0.4);
  border-radius: 10px;
  padding: 5px;
  color: white;
  width: 100%;
  text-align: center;
  font-size: 1.5vh;
  z-index: 4;
}

.add-image-back-button:hover {
  background: var(--light-neutral-color);
  border: none;
  outline: none;
}

.like, .liked {
  height: 22px;
  width: 25px;
  cursor: pointer;
  stroke-width: 1px;
}

.like {
  color: white;
}

.like:hover {
  color: red !important;
}

.liked {
  color: #cf003b;
  stroke: #cf003b;
  animation: like 0.5s ease-in-out forwards;
}

@keyframes like {
  0% {
      color: gray;
      stroke: gray;
      transform: scale(1);
  }
  50% {
      transform: scale(0);
  }
  100% {
      transform: scale(1);
  }
}


.dzu-dropzone {
  overflow: auto !important;
  border: none !important;
  min-height: unset !important;
  position: unset !important;
}

.dzu-submitButtonContainer {
  margin: auto !important;
  position: absolute;
  bottom: 5px;
}

.dzu-inputLabelWithFiles {
  display: none !important;
  position: absolute;
  bottom: 5px;
}

.cancel-preview {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 20px;
  left: 10px;
  border-radius: 50%;
  padding: 8px;
  z-index: 2;
  color: white;
  background: rgba(0,0,0,0.4);
}

.cancel-preview:hover {
  cursor: pointer;
  background-color: #b0757597;
}

.video-container {
  transition: all .2s ease;
  margin: auto;
}

.video-player {
  transition: all .2s ease;
}

.play-button {
  position: absolute;
  height: 50px;
  width: 50px;
  margin: auto;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  background: black;
  fill: white;
  border-radius: 50%;
}

.play-button:hover {
  cursor: pointer;
}

.mute-button {
  position: absolute;
  top: calc(50% - 18.5px);
  right: calc(50% - 18.5px);
  height: 30px;
  width: 30px;
  background: rgba(0,0,0,0.4);
  border-radius: 50%;
  padding: 7px;
  z-index: 2;
}

@media only screen and (max-width: 600px) {
  .image-delete-button {
    font-size: 14px;
    width: 1.8em;
  }
  .image-likes-container {
    font-size: 14px;
  }
}