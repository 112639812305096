.search-page {
    z-index: 4;
    position: fixed;
    background: var(--dark-mode-middleground);
    height: 10.5em;
    width: 600px;
    left: calc(50% - 300px);
    text-align: center;
    transform: translate(0, -10.5em);
    transition: all 0.3s ease;
    border-radius: 0 0 25px 25px;
}

.search-pulldown {
    position: fixed;
    display: flex;
    height: 2.5em;
    width: 2.5em;
    border-radius: 50%;
    right: 1em;
    top: 1em;
    z-index: 4;
}

.search-pulldown:hover {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .search-pulldown {
        left: unset !important;
        right: 1em !important;
    }

    .search-page {
        width: 100% !important;
        left: unset !important
    }

    .search-bar {
        width: 90% !important;
    }

    .search-header {
        font-size: 4em !important;
    }
}

.search-bar {
    font-size: 16px;
    position: relative;
    margin: auto;
    width: 50vw;
    max-width: 500px;
    z-index: 1;
}

.search-header {
    font-size: 5em;
    color: var(--gray);
}

.search-header-haram {
    color: var(--haram-compliment-color);
}

.search-header-halal {
    color: var(--halal-compliment-color);
}

.search-bar-input,
.search-bar-input-autocomplete-open {
    margin: 8px 0 0 0 !important;
    padding: 1em 3em !important;
    border: none !important;

    /* darkmode styles */
    background: var(--dark-mode-foreground) !important;
    color: var(--dark-mode-text-color);
}

.search-bar-input:hover {
    box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
}

.search-bar-input-autocomplete-open {
    box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
    border-radius: 24px 24px 0 0 !important;
    border: none;
}

.autocomplete {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
    /* background-color: white; */
    background: var(--dark-mode-foreground);
    color: var(--dark-mode-text-color);
    border-radius: 0 0 24px 24px;
    list-style-type: none;
    text-align: left;
}

.autocomplete-list {
    flex: auto;
    padding-bottom: 8px;
    padding-left: 0;
    margin-top: 0;
}

.autocomplete-list-item, .autocomplete-list-item-highlighted {
    display: flex;
    align-items: center;
    min-width: 0;
    padding: 0;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.autocomplete-list-item-highlighted {
    /* background: #eee; */
    background: var(--dark-mode-highlight-color);
}

.suggestions-inner-container {
    flex: auto;
    display: flex;
    margin: 0 20px;
    align-items: center;
    margin: 0 20px 0 3em;
}

.option {
    display: flex;
    flex: auto;
    flex-direction: row;
    min-width: 0;
    padding: 6px 0;
    cursor: pointer;
}

.add-topic-option-text {
    max-width: max-content;
    color: var(--link-color)
}

.add-topic-option-topic-text {
    max-width: max-content;
    margin-left: 0.5rem;
}

.search-icon-container {
    position: absolute;
    padding: 1.55em 1.2em;
}