/* halalvote logo */
.logo {
    text-align: center;
    margin-top: 0;
    padding-top: 0;
}
#h {
   font-size: 64px;
   color: var(--haram-compliment-color);
}

#v {
    font-size: 64px;
    color: var(--halal-compliment-color);
}

.here {
    display: inline-block;
    text-decoration: underline;
}

.login-body {
    width: 100%;
    height: 100%;
    display: block;
    padding-top: 2.5vh;
}

.login-section-text {
    width: 100%;
    text-align: center;
    padding: 10vh 0px 10px 0px;
    font-size: 24px;
    color: var(--dark-mode-text-color);
}

.login-input-container {
    display: flex;
}

.login-input-error {
    margin: auto;
}

#login-submit-button, #register-submit-button {
    background-color: var(--dark-mode-background);
    border: 1px solid var(--site-background-color);
    padding: 15px 0px;
    margin-top: 20px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
}

.login-switch-buttons-container {
    display: block;
    padding: 0 10px;
    margin: 0 10px;
    font-size: .85em;
}

.login-switch-button {
    width: fit-content;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    cursor: pointer;
    color: var(--dark-mode-text-color);
    line-height: 25px;
}

.login-switch-button:hover {
    text-decoration: underline;
}

.login-error-message {
    text-align: center;
    color: red;
}

.login-link {
    cursor: pointer;
    text-decoration: underline;
}