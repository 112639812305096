@media only screen and (max-width: 600px) {
    .bm-burger-button {
        width: 1.5em !important;
        height: 1.25em !important;
        left: 1.5em !important;
        top: 1.5em !important;
    }
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 2em;
  height: 1.5em;
  left: 1.5em;
  top: 1.5em;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--dark-mode-text-color);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  transition: .3s ease-in-out !important;
}

/* General sidebar styles */
.bm-menu {
    background: var(--site-background-color);
    padding: 2.5em 0 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    width: 90%;
    margin: 0;
}

/* Individual item */
.bm-item {
    width: 100%;
    cursor: pointer;
    background: transparent;
    text-align: center;
    transition: all .2s ease-in-out;
    color: var(--dark-mode-text-color);
    padding: 1.5em 0;
}

.bm-item:focus {
    outline: none;
}

.bm-item:hover {
    background-color: var(--neutral-color);
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item {
  display: flex !important;
  align-items: center;
  padding-left: 10%;
  width: 100%;
}

.menu-item-bottom-section {
  position: absolute;
  width: 90%;
  bottom: 0;
  align-items: center;
}