html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: verdana, arial, helvetica, sans-serif;
  color: #616161;
  background-color: var(--dark-mode-background);
  position: relative;
  transition: background-color 0.5s linear;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  html, body {
    background-color: black !important;
  }

  .delete-button {
    font-size: 9px !important;
}
}

:root {
  --haram-color: #D0ADEB;
  --haram-color-transparent: rgb(208, 173, 235, 0.75);
  --halal-color: #A9DDD6;
  --halal-color-transparent: rgb(169, 221, 214, 0.75);

  --haram-compliment-color: #8756ad;
  --haram-compliment-color-transparent: rgb(171, 107, 219, 0.75);
  --halal-compliment-color: #57998e;
  --halal-compliment-color-transparent: rgb(124, 203, 190, 0.75);

  --light-neutral-color: #5f5f5f;
  --neutral-color: #414141;
  --neutral-color-transparent: rgb(106, 124, 138, 0.75);

  --link-color: #5f5f5f;
  --gray: #232B2F;
  --dark-mode-background: #181818;
  --dark-mode-middleground: #2a2a2a;
  --dark-mode-middleground-transparent: #2a2a2a75;
  --dark-mode-foreground: #414141;
  --dark-mode-text-color: rgb(197, 197, 197);
  --dark-mode-secondary-text-color: rgb(136, 136, 136);
  --dark-mode-highlight-color: rgb(49, 49, 49);
  --dark-mode-red: #9c435d;
  --dark-mode-halal-color: #4f8a82;
  --dark-mode-haram-color: #7f5f98;

  /* --site-background-color: #d4d4d4; */
  --site-background-color: #181818;

  --toolbar-height-vh: 11vh;
  --max-toolbar-height-px: 50px;
  --topic-carousel-height-vh: 13vh;
  --max-topic-carousel-height-px: 135px;

  --highlight-color: var(--light-neutral-color);

  --button-background-color: #f2f2f2;
}

.dark-mode {
  --dark-mode-background: #181818;
  --dark-mode-middleground: #2a2a2a;
  --dark-mode-foreground: #414141;
  --dark-mode-text-color: rgb(221, 221, 221);
  --dark-mode-secondary-text-color: rgb(141, 141, 141);
  --dark-mode-highlight-color: rgb(49, 49, 49);
  --dark-mode-red: #9a2f4e;
  --dark-mode-halal-color: #4f8a82;
  --dark-mode-haram-color: #7f5f98;
}

input[type=text]/*, input[type=password]*/ {
  border-radius: 24px;
  border: 1px solid #dfe1e5;
  outline: none;
  font-size: 16px;
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  /* darkmode styles */
  background: var(--dark-mode-foreground);
  color: var(--dark-mode-text-color);
}

.form-input {
  width: 80% !important;
  margin: 10px 0 10px 10%;
  outline: none;
  font-size: 16px;
  width: 100%;
  padding: 6px 20px;
  display: inline-block;
  box-sizing: border-box;
  background-color: transparent;
  color: #dfe1e5;
  border-bottom: 1px solid var(--dark-mode-background);
  border-top: none;
  border-left: none;
  border-right: none;
  transition: .5s;
}

.form-input:focus, .form-input:hover {
  border-bottom: 1px solid #dfe1e5;
}

.button, .disabled-button {
  width: 80%;
  padding: 6px;
  margin: 10% 0 0 10%;
  border-radius: 24px;
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-foreground);
  border: none;
  outline: none;
  font-size: 16px;
}

.disabled-button {
  opacity: 0.5;
}

.button:hover {
  border: none !important;
  background-color: var(--light-neutral-color) !important;
  cursor: pointer !important;
}

.disabled-button:hover {
  cursor: not-allowed !important;
  background-color: var(--dark-mode-foreground) !important;
}

.caution-button {
  background-color: darkred !important;
}

.caution-button:hover {
  background-color: red !important;
}

.delete-button {
  padding-left: 1em;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
}

@media only screen and (min-width: 600px) {
  .delete-button:hover {
    cursor: pointer;
    color: transparent;  
    text-shadow: 0 0 0 red;
  }  
}

@media only screen and (max-width: 599px) {
  .delete-button:active {
    cursor: pointer;
    color: transparent;  
    text-shadow: 0 0 0 red;
  }  
}

.back-action {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 20px;
  left: 10px;
  border-radius: 50%;
  padding: 5px;
}

.back-action:hover {
  cursor: pointer;
  background-color: #ffffff25;
}