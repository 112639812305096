.topic-container-mobile {
    position: fixed;
    display: block;
    height: 100%;
    width: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    transition: transform 0.3s ease;
}

.topic-container-mobile::-webkit-scrollbar {
    display: none;
}

.topic-media-scale-div {
    transform: scale(1);
    transform-origin: top;
    height: calc(100% - var(--max-topic-carousel-height-px));
    width: 100%;
    position: absolute;
    background: black;
    border-radius: 0 0 8px 8px;
}

.topic-media-container {
    position: absolute;
    height: 100%;
    width: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    transform: scale(1);
    transition: all 0.3s ease;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.topic-container-footer {
    position: relative;
    top: 100%;
    height: 80%;
    transform: translate(0px, calc(-1 * var(--max-topic-carousel-height-px)));
    display: block;
    width: 100%;
    background: var(--dark-mode-background);
    transition: background-color 0.5s linear;
    z-index: 2;
    border-radius: 12px 12px 0 0;
}

.pull-up-container {
    position: relative;
    background: inherit;
    margin: auto;
    width: 90%;
    height: 13.5px;
}

.pull-up-tab {
    position: relative;
    width: 34px;
    height: 3.5px;
    top: calc(50% - 1.25px);
    left: calc(50% - 17px);
    background: var(--dark-mode-foreground);
    border-radius: 10px;
    z-index: 1;
}

.topic-container-footer-content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 13.5px);
    width: 100%;
}

.topic-carousel-container {
    transform: translate(0, 0);
    transition: all 0.3s ease-in-out;
}

.full-screen-topic::-webkit-scrollbar {
    display: none;
}

.topic-carousel-container-fs-absolute {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 4;
}

.topic-carousel-container-fs-fixed {
    position: fixed;
    z-index: 4;
}
